/* .header {
    background-color: #fff;
    font-size: 1.5rem;
} */
.ant-menu-horizontal {
    height: 100%; /* This ensures that the menu takes up full height of the header */
}

.ant-menu-horizontal .ant-menu-item-selected {
    background-color: #1890ff;
    color: #fff;
    border-radius: 4px;
    font-weight: bold;
    height: 100%;
    display: flex;
    align-items: center; 
}

.header-nav li:last-child {
    border-right: none;
}

.login-status {
    font-size: 1.25rem;
    color: #ffffff;
}


.header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 0px; /* Adjust padding as necessary */
    background-color: #110f24; /* Example background color */
    color: #fff; /* Example text color */
    height: 3.5rem;
}

.full-width-menu {
    flex-grow: 1;
    display: flex;
    align-items: center;
    font-size: 1.25rem;   
}


