.questionAndimage-lesson{
    width: 100%;
    display: flex;
}

.image-lesson-matching {
    max-width: 80px;
    margin-top: 80px;
    margin-left: 15px;
    justify-content: center;
}

.image-lesson-matching img{
    max-width: 300px;
    max-height: 550px;
    margin-top: 30px;
}

.question-matching-lesson {
    width: 700px;
}

.lesson-matches-list {
    display: flex;
    align-items: center;
    width: 100%;
}

.lesson-matches-label {
    margin-left: -2px;
    margin-right: 2px;
}

.lesson-option1-option2-msg-title {
    margin-top: 12px;
    width: 100%;
    display: flex;
    margin-bottom: 2px;
}

.lesson-option1-option2-msg-option1 {
    width: 28%;
    font-size: 18px;
    padding-left: 7%;
}

.lesson-option1-option2-msg-match {
    width: 22%;
    font-size: 18px;
    padding-left: 9.5%;
    margin-left: -31px;
}

.lesson-option1-option2-msg-option2 {
    width: 32%;
    font-size: 18px;
    padding-left: 5.5%;
    margin-left: -37px;
}

.lesson-option1-option2-msg-msg {
    width: 19%;
    font-size: 18px;
    padding-left: 3.5%;
}

.lesson-matches-content- {
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding-top:10px;
}

.lesson-jsplumb-container {
    width: 100%;
    height: auto; 
    overflow: hidden;
    position: relative;
}

/* .jsplumb-container {
    position: relative;
    width: 100%;
    height: 400px;
  } */

.lesson-match {
    width: 90px;
    margin-left: 25px;
    margin-right: 20px;
}

.lesson-match-msg-label {
    margin-left: 8px;
    margin-right: 10px;
}

.lesson-match-msg-content {
    display: flex;
    align-items: center;
    margin-left: 12px;
    width: 19%;
}

.delete-match {
    font-size: 20px;
    margin-left: 7px;
    cursor: pointer;
}

.ant-input{
    &::placeholder {
        color : rgb(117, 117, 124);
    }
}

.default-msg-- {
    width: 100%;
    display: flex;
    margin-top: 10px;
    align-items: center;
    justify-content: flex-start;
    margin-left: 310px;
}

.default-msg-title- {
    width: 152px;
    /* margin-right: 15px; */
    font-size: 17px;
}

.lesson-default-msg-content {
    display: flex;
    align-items: center;
    /* width: 15%; */
}

.matching-question-comment {
    margin-top: 20px;
}
