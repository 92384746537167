/* body {
    display: flex;
    justify-content: center;
    width: 100%;
    min-width: 1400px;
    background-color: rgb(255, 255, 255);
} */

.question-page-body {
    display: flex;
    min-width: 1300px;
    justify-content: center;
    padding: 3rem;
    background-color: white;
    border-radius: 0.5rem;
    margin-top: 1rem;

}

.quiz-list {
    width: 50%;
}

.edit-quiz-title {
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
}

.quiz-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
}

.quiz-item:hover,
.quiz-item:focus {
    background-color: #e8e8e8;
}

.quiz-title {
    cursor: pointer;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 1rem;
}

.quiz-title.active {
    background-color: #e8e8e8;
    /* Light gray background for selected item */
    color: #000000;
    /* Darker text for better contrast */
}

.delete-mark {
    /* padding-right: 15px; */
    cursor: pointer;
    margin-left: 5px;
}

.question-edit-area {
    width: 60%;
    min-width: 600px;
    max-width: 622px;
}

.operation-area {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.ant-btn {
    margin-top: 2rem;
}

.ant-breadcrumbs {
    margin-top: 4rem;
}