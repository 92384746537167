
.aipart-body-lesson {
    min-width: 1000px;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
}

.aiquestion-body-lesson {
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    /* width: 1000px; */
    width: 100%;
    margin-top: 25px;
    margin-right: 50px;
}

.aiquestion-input-lesson {
    width: 90%;
    height: 250px;
    /* border: 1px solid black; */
    /* box-sizing: border-box; */
    border-radius: 10px;
    margin-bottom: 20px;
    display: flex;
}


.aiquestion-input-right-lesson {
    width: 100%;
    height: 218px;
    margin: 10px;
    padding: 20px;
    border: 1px solid black;
    border-radius: 10px;
}

/* .aiquestion-input-right {
    width: 100%;
    height: 218px;
    margin: 10px;
    padding: 20px;
    border: 1px solid black;
    border-radius: 10px;
} */

.aiquestion-input-box {
    width: 100%;
    height: 100%;
}

.aiquestion-output-bottom {
    /* width: 88%; */
    width: 88%;
    /* width: 100%; */
    min-height: 380px;
    max-height: 380px;
    border: 1px solid black;
    border-radius: 10px;
    display: flex;
}

.aiquestion-output-lesson {
    width: 100%;
    min-height: 90%;
    max-height: 90%;
    /* min-width: 360px; */
    margin: 10px;
    padding: 20px;
    border: 1px solid black;
    border-radius: 10px;
    white-space: pre-wrap; 
    overflow-y: auto;
}