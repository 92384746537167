.object_placement_layout {
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 35px;
    width: 100%;
    /* justify-content: center; */
}

.op_tagid {
    width: 48%;
    font-size: 18px;
}

.op_msg {
    width: 50%;
    font-size: 18px;
}

.tagmsg-list {
    display: flex;
    align-items: center;
    width: 100%;
    /* max-width: 500px; */
}

.tagid-content {
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding-top:10px;
    width: 45%;
}

.msg-content {
    flex-grow: 1;
    display: flex;
    align-items: center;
    width: 45%;
}