.questionAndimage-lesson{
    width: 100%;
    display: flex;
}

.image-lesson-op {
    max-width: 300px;
    
    margin-left: 15px;
    justify-content: center;
}

.image-lesson-op img{
    max-width: 300px;
    max-height: 550px;
    margin-top: 30px;
}

.question-op-lesson {
    width: 700px;
}

.object_placement_layout {
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 35px;
    width: 100%;
    /* justify-content: center; */
}

.op_tagid-lesson {
    width: 20%;
    font-size: 18px;
}

.op_msg-lesson {
    width: 70%;
    margin-left: 3.5%;
    font-size: 18px;
}

.tagmsg-list {
    display: flex;
    align-items: center;
    width: 100%;
    /* max-width: 500px; */
}

.tagid-content-lesson {
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding-top:10px;
    width: 20%;
}

.msg-content-lesson {
    flex-grow: 1;
    display: flex;
    align-items: center;
    width: 75%;
}