.questionAndimage-lesson{
    width: 100%;
    display: flex;
}

.image-lesson-mc {
    max-width: 300px;
    
    margin-left: 15px;
    justify-content: center;
}

.image-lesson-mc img{
    max-width: 300px;
    max-height: 550px;
}

.question-mc-lesson {
    width: 700px;
}

.question-content-container{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.options-list {
    display: flex;
    align-items: center;
    width: 100%;
}

/* .options-list:before {
    content: '\2807\2807';
    font-size: 15px;
    cursor: pointer;
    letter-spacing: -7px;
    margin-right: 5px;
} */

.options-label {
    margin-left: 2px;
    margin-right: 10px;
}

/* .options-label label {
    cursor: pointer;
} */

.option-msg-title {
    margin-top: 12px;
    width: 100%;
    display: flex;
}

.option-msg-option-lesson {
    width: 51%;
    font-size: 18px;
    margin-left: 49px;
}

.option-msg-msg-lesson {
    width: 40%;
    font-size: 18px;
    padding-left: 4%;
}
  
.options-content {
    flex-grow: 1;
    display: flex;
    align-items: center;
    /* width: 100%; */
    padding-top:10px;
}

.comment-content- {
    display: flex;
    align-items: center;
    width: 38%;
}

.delete-option {
    font-size: 20px;
    margin-left: 10px;
    cursor: pointer;
}

.ant-input{
    &::placeholder {
        color : rgb(117, 117, 124);
    }
}