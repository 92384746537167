/* body {
    display: flex;
    justify-content: center;
    min-width: 1400px;
} */

.lesson-page-body {
    display: flex;
    min-width: 1300px;
    justify-content: center;
    padding: 3rem;
    background-color: white;
    border-radius: 0.5rem;
    margin-top: 1rem;

}