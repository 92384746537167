.question-display-header {
    display: flex;
    width: 100%;
}
.question-display-header p {
    margin-left: 2px;
    width: 10%;
}

.question-pagination {
    width: 90%;
}

.question-box {
    display: flex;
    user-select: none;
}

.question-text {
    font-size: 16px;
    width: 100%;
    margin-bottom: 15px;
}

.table-option-list {
    width: 33.33%;
    text-align: left;
    border: 1px solid #ddd;
    padding: 8px;
    word-break: break-word; 
}

.table-option {
    width: 100%;
    border-collapse: collapse;
}

.correct-answer {
    font-weight: bold;
    border: 1px solid #ddd;
    padding: 8px;
}
