/* body {
    display: flex;
    justify-content: center;
    min-width: 1400px;
} */

.aipart-body {
    min-width: 1400px;
    display: flex;
    flex-direction: column; 
    /* justify-content: center; */
    align-items: center;
}

.aiquestion-body {
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    width: 1300px;
    margin-top: 25px;
}

.aiquestion-input {
    width: 90%;
    height: 250px;
    /* border: 1px solid black; */
    /* box-sizing: border-box; */
    border-radius: 10px;
    margin-bottom: 20px;
    display: flex;
}

.aiquestion-input-left {
    width: 50%;
    height: 218px;
    margin: 10px;
    padding: 20px;
    border: 1px solid black;
    border-radius: 10px;
}

.label-title {
    height: 20px;
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 20px;
}

.label-list {
    margin-left: 70px;
}

.label-list li {
    display: flex;
    align-items: center;
    height: 30px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.label-list li input {
    width: 40px;
    height: 20px;
}

.type-label {
    width: 120px;
    margin-right: 15px;
}

.aiquestion-input-right {
    width: 65%;
    height: 218px;
    margin: 10px;
    padding: 20px;
    border: 1px solid black;
    border-radius: 10px;
}

.aiquestion-input-box {
    width: 100%;
    height: 100%;
}

.aiquestion-output {
    width: 88%;
    min-height: 380px;
    /* max-height: 380px; */
    border: 1px solid black;
    border-radius: 10px;
    display: flex;
}

.aiquestion-output-left {
    width: 33%;
    min-height: 90%;
    margin: 10px;
    padding: 20px;
    border: 1px solid black;
    border-radius: 10px;
}

.geminiImg {
    /* width: 100%; */
    width: 335px;
    display: flex;
    flex-direction: column;
    justify-self: center;
    position: fixed;
}

.geminiImg p {
    margin-left: 25px;
    font-size: 20px;
}

.geminiImg-img img {
    max-width: 90%;
}

.aiquestion-output-right {
    width: 64%;
    min-height: 90%;
    /* min-width: 360px; */
    margin: 10px;
    padding: 20px;
    border: 1px solid black;
    border-radius: 10px;
    white-space: pre-wrap; 
}