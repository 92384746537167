.signup {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.signup-logo img {
    width: 125px;
    /* height: 85px; */
    height: auto;
    display: block;
    /* margin: 0 auto 20px; */
}

.signup-container {
    width: 400px;
    height: 540px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50% );
    box-shadow: 0 0 50px rgb(0 0 0 / 10%);
}

.signup-checkbox-lable{
    color: #1890ff;
}

