.option-ranking-area {
    display: flex;
    width: 100%;
}

.ranking-list {
    display: flex;
    align-items: center;
    /* width: 100%; */
    width: 352px;
}

.ranking-label {
    margin-left: 2px;
    margin-right: 10px;
}

.messages-list {
    display: flex;
    align-items: center;
    width: 120px;
}

.ranking-content {
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding-top: 10px;
}

.messages-content {
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding-top: 10px;
}

.delete-ranking {
    font-size: 20px;
    cursor: pointer;
}

.ant-input {
    &::placeholder {
        color: rgb(117, 117, 124);
    }
}

.draggable-handle {
    cursor: grab;
    margin-right: 10px;
}

.option-order-msg {
    width: 35%;
    font-size: 18px;
    display: flex;
    margin-top: 18px;
    margin-bottom: 0px;
}

.option-order-msg-option {
    margin-left: 52px;
}

.option-oerder-msg-order {
    margin-left: 232px;
}

.option-oerder-msg-msg {
    margin-left: 114px;
}


