.lesson-bank-preview-container {
    background-color: rgb(255, 255, 255);
    margin-top: 20px;
    /* padding-top: 10px; */
    padding-bottom: 1px;
    border-radius: 8px;
}
.lessonbank-preview-header {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    width: 100%;
    padding: 0 20px; 
    box-sizing: border-box;
  }
  
  .lesson-bank-preview {
    /* flex: 0 0 auto;  */
  }
  
  .lesson-preview-header > div:last-child {
    flex: 1;
    text-align: center;
    padding-left: 30px; 
    /* padding-right: 20%; */
  }

.lesson-bank-preview {
    font-size: 2rem;
    font-weight: bold;
    padding: 20px;
    margin-bottom: 3px;
  
}

.lesson-preview-content {
    display: flex;
    width: 100%;
    min-width: 1300px;
    justify-content: center;
}

.lesson-preview-content-left {
    margin: 0 18px;
    /* width: 18rem; */
    width: 20%;
    max-width: 310px;
    /* padding: 10px;
    border-right: 1px solid #757171; */
}

.lesson-preview-pages-list-container {
    max-height: 500px;  /* Adjust height as needed */
    overflow-y: auto;
    overflow-x: hidden;
}


.lesson-preview-content-left-title {
    font-size: 1.5rem;
    font-weight: bold;
}

.lesson-preview-content-right {
    margin: 0 18px;
    width: 60rem;
    margin-bottom: 10px;
    /* border: 1px solid #000 */
}

.blank-page-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.blank-page-lesson-title {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    font-size: 1.75rem;
    font-weight: bold;
}

.blank-page-lesson-description {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    font-size: 1.25rem;
    /* font-weight: bold; */
}

.blank-page-lesson-component {
    margin-top: 20px;
    /* margin-left: 20px; */
    width: 85%;
    font-size: 1.2rem;
}

.blank-page-lesson-layout {
    display: flex;
    margin-top: 40px;
    width: 85%;
}

.blank-page-lesson-layout-left {
    width: 40%;
    display: flex;
    flex-direction: column;
}

.blank-page-lesson-layout-left span {
    margin-bottom: 15px;
    font-size: 1.1rem;
}

.blank-page-lesson-layout-right {
    /* width: 100%; */
    display: flex;
}

.layout-img {
    width: auto;
    height: 200px;;
}

.blank-page-lesson-inst {
    width: 85%;
    font-size: 1.2rem;
    margin-top: 30px;
}

.section-content- {
    /* border: 1px solid #000; */
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0px;
    height: 240px;
    margin-bottom: 0px;
    max-width: 300px;
    /* white-space: pre-wrap; */
}

.section-content- p{
    padding: 0px;
    margin: 0px;
}

.section-content- h1{
    padding: 0px;
    margin: 0px;
}

.section-content- h2{
    padding: 0px;
    margin: 0px;
}

.section-2-preview {
    display: flex;
    height: 240px;
    margin-bottom: 0px;
}

.section-image {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;
    /* border: 1px solid #000; */
    height: 240px;
    margin-bottom: 0px;
}

.lesson-preview-img {  
    margin-top: 10px;
    max-width: 300px;
    max-height: 240px;
    /* border: 1px solid #000; */
}

.section-question-1 {
    /* border: 1px solid #000; */
    flex-basis: 100%; 
    display: flex;
    flex-direction: column; 
    padding: 0px;
    margin-top: -20px;
}

.section-question-2 {
    /* border: 1px solid #000; */
    flex-basis: 100%; 
    display: flex;
    flex-direction: column; 
    padding: 0px;
    margin-top: -20px;
}

.object-placement-circle {
    width: 100px;       
    height: 100px;      
    border: 2px solid black;
    padding: 10px; 
    margin-left: 105px;
    margin-top: 10px;
    border-radius: 50%; 
    display: flex;      
    justify-content: center; 
    align-items: center; 
}

.page-number {
    /* margin-left: 720px; */
    position: absolute;   /* Position the element absolutely within its parent */
    top: 0;               /* Align to the top of the parent */
    right: 0;             /* Align to the right of the parent */
    margin-right: 30px;   /* Add some margin if needed */
    margin-top: 20px;
}
  
.dashed-lines-horizontal::before {
    content: ""; /* Required for display */
    position: absolute; /* Positions relative to the parent */
    width: 100%; /* Line spans the full width */
    height: 0px; /* No height for the line itself */
    border-top: 1px dashed #a3a1a1; /* Dashed line style */
    top: 48.5%; /* Position the line in the middle vertically */
}

.dashed-lines-vertical::after {
    content: ""; /* Required for display */
    position: absolute; /* Positions relative to the parent */
    /* height: 100%;  */
    height: 92.5%;
    width: 0px; /* No width for the line itself */
    border-left: 1px dashed #a3a1a1; /* Dashed line style */
    left: 50%; /* Position the line in the middle horizontally */
    top: 7.5%;
}