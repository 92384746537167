.question-display-header {
    display: flex;
    width: 100%;
    /* justify-content: center; */
    align-items: center;
}
.question-display-header p {
    margin-left: 2px;
    width: 10%;
}

.question-pagination-lesson {
    width: 100%;
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
}

.question-box {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: center;
    user-select: none;
}

.question-text {
    font-size: 16px;
    width: 100%;
    margin-bottom: 15px;
}

.table-option-list {
    width: 33.33%;
    text-align: left;
    border: 1px solid #ddd;
    padding: 8px;
    word-break: break-word; 
}

.table-option {
    width: 100%;
    border-collapse: collapse;
}

.correct-answer {
    font-weight: bold;
    border: 1px solid #ddd;
    padding: 8px;
}




.question-card {
    border: 1px solid #ddd; 
    border-radius: 4px;    

}

.no-border {
    border-collapse: collapse;
    border: none;
    width: 100%; 
}

.no-border td {
    border: none;
}

.correct-answer {
    /* background-color: #d4edda;  */
    font-weight: bold;
}

.table-option-list {
    /* padding: 8px 0;  */
}

