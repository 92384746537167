.mycreatelesson-container {
    display: flex;
    flex-direction: column;
    min-width: 1300px;
    justify-content: center;
    padding-left: 3rem;
    padding-right: 3rem;
    background-color: white;
    border-radius: 0.5rem;
    margin-top: 1rem;
}

.createlesson-steps-lesson {
    margin-bottom: 5px;
}

.createlesson-page-body {
    display: flex;
    flex-direction: column;
    min-width: 1000px;
    justify-content: center;
    /* padding: 3rem; */
}

.createlesson-page-title {
    display: flex;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0px;
    user-select: none;
}

.createlesson-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.createlesson-content-left {
    display: flex;
    flex-direction: column;
    width: 25rem;
    margin-right: 5rem;
}

.createlesson-content-title {
    margin-bottom: 20px;
}

.createlesson-content-description {

}

.createlesson-content-right {
    display: flex;
    flex-direction: column;
    width: 30rem;
}

.createlesson-content-right-title {
    font-size: 1.5rem;
    font-weight: bold;
}

.lesson-bank-list-import {
    display: flex;
}

.lesson-bank-list {
    width: 100%;
}

.handleNext {
    display: flex;
    justify-content: flex-end;
    margin-right: 3rem;
}

.handleNext_ {
    display: flex;
    justify-content: space-between;
    padding: 0 17rem;
    margin-bottom: 40px;
}

.handleNext_preview {
    width: 95%;
    display: flex;
    justify-content: space-between;
    position: fixed; 
    top: 80%;
    padding: 0 17rem;
    margin-bottom: 40px;
    z-index: 1;
}

.preview-lesson-text {
    width: 400px;
    position: fixed; 
    top: 76%;
    right: 11%;
    color: rgb(255, 0, 0);
}

/* .handleNext_preview {
    width: 330px;
    display: flex;
    justify-content: space-between;
    position: fixed; 
    right: 10%;
    top: 85%;
} */

.handleNext_pdf {
    width: 380px;
    display: flex;
    justify-content: space-between;
    /* position: fixed;  */
    position: absolute;
    right: 13%;
    /* right: 130px; */
    top: 14%;
}

/* .hidden-div {
    height: 0;
    overflow: hidden;
} */
