.lesson-preview-content {
    display: flex;
    width: 100%;
    min-width: 1300px;
    justify-content: center;
}

.lesson-preview-content-left {
    margin: 0 18px;
    /* width: 18rem; */
    width: 20%;
    /* padding: 10px;
    border-right: 1px solid #757171; */
}

.lesson-preview-pages-list-container {
    max-height: 500px;  /* Adjust height as needed */
    overflow-y: auto;
    overflow-x: hidden;
}


.lesson-preview-content-left-title {
    font-size: 1.5rem;
    font-weight: bold;
}

.lesson-preview-content-right {
    margin: 0 18px;
    width: 60rem;
    margin-bottom: 10px;
    /* border: 1px solid #000 */
}

.prereview-layout-lesson {
    width: 780px;
    height: 602px;
    display: flex;
    flex-direction: column;
    justify-content: center;  
    align-items: center;    
    margin-top: 20px;
    border: 1px solid black; 
}

.dashed-lines-horizontal::before {
    content: ""; /* Required for display */
    position: absolute; /* Positions relative to the parent */
    width: 100%; /* Line spans the full width */
    height: 0px; /* No height for the line itself */
    border-top: 1px dashed #a3a1a1; /* Dashed line style */
    top: 48.5%; /* Position the line in the middle vertically */
}

.dashed-lines-vertical::after {
    content: ""; /* Required for display */
    position: absolute; /* Positions relative to the parent */
    /* height: 100%;  */
    height: 92.5%;
    width: 0px; /* No width for the line itself */
    border-left: 1px dashed #a3a1a1; /* Dashed line style */
    left: 50%; /* Position the line in the middle horizontally */
    top: 7.5%;
}

.prereview-pdf-lesson {
    width: 100%;
    height: 100%;
}

.blank-page-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.lesson-title-pages {
    display: flex;
    width: 100%;
}

.lesson-title- {
    width: 73%;
    padding-left: 25px;
    height: 118px;
}

.lesson-title- h2 {
   padding: 0px;
   padding-top: 10px;
   margin: 0px;
}

.lesson-title- p {
    padding: 0px;
    padding-top: 2px;
    margin: 0px;
 }

.lesson-pages-components {
    margin-top: 20px;
    width: 27%;
}

.instruction-png {
    width: 760px;
    height: 470px;
}

.blank-page-lesson-title {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    font-size: 1.75rem;
    font-weight: bold;
}

.blank-page-lesson-description {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    font-size: 1.25rem;
    /* font-weight: bold; */
}

.blank-page-lesson-component {
    margin-top: 20px;
    /* margin-left: 20px; */
    width: 85%;
    font-size: 1.2rem;
}

.blank-page-lesson-layout {
    display: flex;
    margin-top: 40px;
    width: 85%;
}

.blank-page-lesson-layout-left {
    width: 40%;
    display: flex;
    flex-direction: column;
}

.blank-page-lesson-layout-left span {
    margin-bottom: 15px;
    font-size: 1.1rem;
}

.blank-page-lesson-layout-right {
    /* width: 100%; */
    display: flex;
}

.layout-img {
    width: auto;
    height: 200px;;
}

.blank-page-lesson-inst {
    width: 85%;
    font-size: 1.2rem;
    margin-top: 30px;
}

.section-content {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 10px;
    height: 240px;
    margin-bottom: 0px;
    max-width: 390px;
    overflow: hidden;
    hyphens: auto;
    word-wrap: break-word;
    text-align: justify;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
}

/* .section-content p{
    padding: 0px;
    margin: 0px;
    word-break: break-word;
    hyphens: auto;
    word-wrap: break-word;
    text-align: justify;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
}

.section-content h1{
    padding: 0px;
    margin: 0px;
    word-break: break-word;
}

.section-content h2{
    padding: 0px;
    margin: 0px;
    word-break: break-word;
}

.section-content ul{
    padding-top: 0px;
    padding-bottom: 0px;
    margin: 0px;
    overflow-wrap: break-word;
    word-break: break-word;
} */



.section-2-preview {
    display: flex;
    height: 240px;
    margin-bottom: 0px;
}

.section-image {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center; 
    justify-content: center;
    /* border: 1px solid #000; */
    height: 240px;
    margin-bottom: 0px;
}

.section-image::before {
    content: "";
    position: absolute;
    left: 49.85%;
    top: 7.5%;
    bottom: 51.5%;
    width: 3px; /* Adjust the width of the line as needed */
    background-color: rgb(255, 255, 255); /* Color of the line */
    z-index: 10;
}

.lesson-preview-img {  
    margin-top: 10px;
    max-width: 340px;
    max-height: 240px;
    /* border: 1px solid #000; */
}

.section-question-1 {
    /* border: 1px solid #000; */
    flex-basis: 100%; 
    display: flex;
    flex-direction: column; 
    padding: 0px;
    margin-top: -20px;
    /* height:275px; */
}

.question-box-empty-op {
    
    padding: 30px;
    padding-top: 100px;
    color:#a3a1a1;
}

.question-box-empty {
    
    padding: 30px;
    padding-top: 140px;
    color:#a3a1a1;
}

.no-print {
    display: none !important;
}

.question-box-lesson-op {
    width: 90%;
    height: 90%; 
    margin-top: 23.5px;
    margin-left: 19px;
    border: 1px solid #000;
    border-radius: 12px;
}

.ls-question-slot-options-op {
    margin-left: 18px;
    margin-top: 0px;
} 

.question-box-lesson {
    width: 90%;
    height: 87%; 
    margin-top: 14.5px;
    margin-left: 19px;
    border: 1px solid #000;
    border-radius: 12px;
}

.ls-question-slot-title {
    height: 54px;
    margin-left: 15px;
    margin-top: 1px;
    font-size: 14.4px;
    /* overflow: hidden; */
    word-wrap: break-word;
    hyphens: auto;
}

.ls-question-slot-options {
    margin-left: -20px;
    margin-top: 0px;
}

.section-question-2 {
    /* border: 1px solid #000; */
    flex-basis: 100%; 
    display: flex;
    flex-direction: column; 
    padding: 0px;
    margin-top: -20px;
}


.object-placement-circle {
    width: 100px;       
    height: 100px;      
    border: 2px solid black;
    padding: 10px; 
    margin-left: 105px;
    margin-top: 10px;
    border-radius: 50%; 
    display: flex;      
    justify-content: center; 
    align-items: center; 
}

.page-number-lesson {
    /* margin-left: 720px; */
    position: absolute;   /* Position the element absolutely within its parent */
    top: 0;               /* Align to the top of the parent */
    right: 0;             /* Align to the right of the parent */
    margin-right: 130px;   
    margin-top: 18px;
}
  

.top-layer-image {
    position: absolute;
    max-width: 70px;
    top: 480px;  
    left: 30px; 
    z-index: 10; 
}

.top-layer-image-2 {
    position: absolute;
    max-width: 70px;
    top: 480px;  
    left: 680px; 
    z-index: 10; 
}

.top-layer-image-3 {
    position: absolute;
    max-width: 70px;
    top: 210px;  
    left: 680px; 
    z-index: 10; 
}

.rfid-image {
    position: absolute;
    max-width: 110px;
    left: 525px; 
}


.matching-style-lesson {
    height: 35.5px;
}