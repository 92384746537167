.true_false_option {
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 15.5px;
    display: flex;
    align-items: center;
    width: 35%;
    /* justify-content: space-between; */
}

.true_false_option_container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.true_false_option_box {
    width: 100px;
    /* margin-left: 100px; */
}

.ant-input{
    &::placeholder {
        color : rgb(117, 117, 124);
    }
}

.option-comment {
    width: 35%;
    font-size: 18px;
    display: flex;
    margin-top: 18px;
    margin-bottom: 0px;
}

.option-comment-option {
    margin-left: 18px;
}

.option-comment-comment {
    margin-left: 48px;
}