.question-content {
    width: 100%;
    display: flex;
}

.options-list {
    display: flex;
    align-items: center;
    width: 100%;
}

/* .options-list:before {
    content: '\2807\2807';
    font-size: 15px;
    cursor: pointer;
    letter-spacing: -7px;
    margin-right: 5px;
} */

.options-label {
    margin-left: 2px;
    margin-right: 10px;
}

/* .options-label label {
    cursor: pointer;
} */

.option-msg-title {
    margin-top: 12px;
    width: 100%;
    display: flex;
}

.option-msg-option {
    width: 80%;
    font-size: 18px;
    /* padding-left: 11%; */
    margin-left: 49px;
}

.option-msg-msg-quiz {
    width: 20%;
    font-size: 18px;
    padding-left: 3%;
}
  
.options-content {
    flex-grow: 1;
    display: flex;
    align-items: center;
    /* width: 100%; */
    padding-top:10px;
}

.comment-content {
    display: flex;
    align-items: center;
    width: 16%;
}

.delete-option {
    font-size: 20px;
    margin-left: 10px;
    cursor: pointer;
}

.ant-input{
    &::placeholder {
        color : rgb(117, 117, 124);
    }
}