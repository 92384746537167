.matching-option-box {
    display: flex;
    align-items: center;
    margin-right: 8px;
}

/* .matching-option-content {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    padding-top: 0px;
    padding-left: 1px;
    padding-right: 0px;
    width: 90px;
    height: 32px;
    min-height: 32px;
    max-height: 32px;
    border: 1px solid black;
    font-size: 12px;
    overflow: hidden;
    word-wrap: break-word;
} */

.matching-option-content- {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 0px;
    padding-left: 1px;
    padding-right: 0px;
    padding-bottom: 0px;
    margin: 0px;
    width: 90px;
    height: 32px;
    min-height: 32px;
    max-height: 32px;
    /* border: 1px solid black; */
    font-size: 12px;
    overflow: hidden;
    word-wrap: break-word;
    hyphens: auto;
}






