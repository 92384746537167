/* 
body {
    display: flex;
    justify-content: center;
    min-width: 1400px;
} */

.questionbank-body {
    display: flex;
    justify-content: center; 
    align-items: center;
    width: 1300px;
    margin-top: 40px;
}

.question-bank-layout {
    margin-top: 10px;
}

.sider-menu {
    user-select: none;
    margin-top: 10px;
}

.header-add-to-quiz {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 50px;
    font-size: 20px;
    font-weight: bold;
    
    user-select: none;
}

.header-add-to-quiz span {
    cursor: pointer;
}


