/* body {
    display: flex;
    justify-content: center;
    width: 100%;
    min-width: 1400px;
    background-color: rgb(255, 255, 255);
} */

.question-page-body {
    display: flex;
    min-width: 1300px;
    justify-content: center;
    margin-top: 1rem;
}

.edit-quiz-title {
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
}

.quiz-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.quiz-title {
    cursor: pointer;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.quiz-title.active {
    background-color: #c0bcbc;
    /* Light gray background for selected item */
    color: #000000;
    /* Darker text for better contrast */
}

.question-manage-container {
    width: 80%;
    min-width: 1000px;
    display: flex;
    justify-content: center;
}

.question-list {
    width: 30%;
    min-width: 300px;
    max-width: 300px;
    margin-right: 35px;
}

.question-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
}

.question-title {
    cursor: pointer;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.question-type {
    margin-left: 17px;
    font-size: 10px;
    font-style: italic;
}

.question-title.active {
    background-color: #c0bcbc;
    /* Light gray background for selected item */
    color: #000000;
    /* Darker text for better contrast */
}

.delete-mark {
    /* padding-right: 15px; */
    cursor: pointer;
    margin-left: 5px;
}

.question-edit-area {
    width: 60%;
    min-width: 600px;
    max-width: 622px;
}

.operation-area {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.question-window {
    position: fixed;
    z-index: 9999;
    /* width: 500px; */
    /* border-radius: 5px; */
}



.question-window ul {
    padding-left: 15px;
}

.question-window li {
    cursor: pointer;
    /* padding: 8px 16px; */
    padding-bottom: 5px;
    list-style-type: none;

}

.question-window li.active {
    background-color: #676869;
    color: rgb(17, 16, 16);
}

.question-window-button {
    display: flex;
    justify-content: space-between;
    margin-top: 0px;
}

.ant-btn {
    margin-top: 1.25rem;
}

.custom-radio-button .ant-radio-button-wrapper {
    border-radius: 0px;
    margin-left: 0px;
    text-align: center; 
    /* width: auto; */
}


