.render-mataches-list {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* margin-bottom: 13px; */
    /* margin-top: 1px; */
    margin-bottom: 2.625px;
    margin-left: 41.5px;
}

.match-opiton {
    min-width: 89px;
    width: 89px;
    text-align: left;
    font-size: 14px;
    margin-right: 30px;
}

.match-match {
    font-size: 14px;
    min-width: 89px;
    width: 89px;
    text-align: left;
}
