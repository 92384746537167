
.options-display {
    margin-left: 112px;
    margin-top: 0px;
}

.render-options-list- {
    width: 100%;
    margin-bottom: 6px;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.option-list-content- {
    /* margin-left: 0px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 0px;
    padding-left: 1px;
    padding-right: 0px;
    padding-bottom: 0px;
    margin: 0px;
    width: 230px;
    height: 32px;
    min-height: 32px;
    max-height: 32px;
    /* border: 1px solid black; */
    font-size: 12px;
    overflow: hidden;
    word-wrap: break-word;
    hyphens: auto;
}

.renderOptionBodyImg {
    display: flex;
    flex-direction: column;
    align-items: center; 
}

.mc_img {  
    margin-top: 10px;
    max-width: 300px;
    max-height: 250px;
}