.img-display {
    position: relative;
    max-width: 100%;
    /* max-height: 200px; */
    margin: auto;
    display: flex;
    justify-content: center; 
    align-items: center;     
    /* overflow: hidden;       */
}

.img-display img {
    width: auto;
    max-width: 100%;    
    height: auto;
    max-width: 400px;
    max-height: 188px;
    min-height: 50px;
    object-fit: contain; 
}

.image-actions {
    position: absolute;
    top: 10px; 
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .image-icon {
    font-size: 21px;
    margin-left: 18px;
    color: white; 
    background-color: rgba(0, 0, 0, 0.6); 
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
  }