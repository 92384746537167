.matches-list {
    display: flex;
    align-items: center;
    width: 100%;
}

.matches-label {
    margin-left: 2px;
    margin-right: 10px;
}

.option1-option2-msg-title {
    margin-top: 12px;
    width: 100%;
    display: flex;
    margin-bottom: 2px;
}

.option1-option2-msg-option1 {
    width: 49%;
    font-size: 18px;
    padding-left: 7%;
}

.option1-option2-msg-option2 {
    width: 32%;
    font-size: 18px;
    padding-left: 7%;
}

.option1-option2-msg-msg {
    width: 18%;
    font-size: 18px;
    padding-left: 5%;
}

.matches-content {
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding-top:10px;
}

.jsplumb-container {
    width: 100%;
    height: auto; 
    overflow: hidden;
}

.draggable-circle, .droppable-circle {
    width: 22px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid #000000;
    margin-left: 3px;
    margin-right: 4px;
    cursor: pointer;
}

.draggable-circle {
    margin-right: 65px;
}

.draggable-circle {
    background-color: rgb(255, 255, 255);
}

.droppable-circle {
    background-color: rgb(255, 255, 255); 
}

/* .match-msg {
    display: flex;
    margin-left: 5px;
} */

.match-msg-label {
    margin-left: 8px;
    margin-right: 10px;
}

.match-msg-content {
    display: flex;
    align-items: center;
    width: 19%;
}

.delete-match {
    font-size: 20px;
    margin-left: 7px;
    cursor: pointer;
}

.ant-input{
    &::placeholder {
        color : rgb(117, 117, 124);
    }
}

.default-msg {
    width: 100%;
    display: flex;
    margin-top: 10px;
    align-items: center;
    justify-content: flex-end;
}

.default-msg-title {
    width: 150px;
    margin-right: 12px;
    font-size: 18px;
}

.default-msg-content {
    display: flex;
    align-items: center;
    width: 15.5%;
}

.matching-question-comment {
    margin-top: 20px;
}