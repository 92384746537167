p {
    white-space: pre-line;
}

.lesson-content-edit{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.page-lesson-title {
    display: flex;
    margin-top: -20px;
    /* margin-left: -15px; */
    margin-bottom: 18px;
    font-size: 2rem;
    font-weight: bold;
    align-items: center;
    user-select: none;
}

.add-page-content {
    display: flex;
    width: 100%;
    min-width: 1300px;
    justify-content: center;
}

.add-page-content-left {
    margin: 0 1.5%;
    /* width: 19rem; */
    width:20%;
    /* padding: 10px;
    border-right: 1px solid #757171; */
}

.pages-list-container {
    max-height: 500px;  /* Adjust height as needed */
    overflow-y: auto;
    overflow-x: hidden;
}


.add-page-content-left-title {
    font-size: 1.5rem;
    font-weight: bold;
}

.add-page-content-right {
    /* margin: 0 2rem; */
    margin: 0 1.5%;
    /* width: 26rem; */
    width: 392px;
}

.instructional-content {
    overflow: hidden;
    hyphens: auto;
    word-wrap: break-word;
    text-align: justify;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
}

.trythis-component {
    width: 430px;
    /* width: 30%; */
    /* margin-left: 20px; */
    /* margin: 0 2rem; */
    margin: 0 5.5%;
}

.encourage-text {
    font-size: 0.9rem;
    font-style: italic;
    color: #FF0000;
}

.add-page-content-title {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 8px;
    margin-top: 10px;
}

.display-questions ul { 
    list-style-type: none;  
    padding: 0;
    
}

.lesson-question-item {
    border-bottom: 1px #a7a5a5 solid; 
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
}

.display-question-title-type {
    margin-bottom: 2px;
    /* padding-bottom: 5px; */
}

.display-questions-title {
    font-size: 1.2rem;
    user-select: none;
}

.display-questions-type {
    font-size: 0.85rem;
    font-style: italic;
    color: red;
    user-select: none;
    font-weight: bold;
}

.ql-toolbar {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}

.ql-container {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}


/* height: 10rem !important; */
/* overflow-y: auto !important; */
/* .ql-editor {
    min-height: 280px !important;
    max-height: 280px !important;
    overflow: hidden !important;  
} */

.add-page-content-img {
    margin-top: 10px;
    margin-left: 10px;
    width: 20rem;
    z-index: 100000;
}

.add-page-content-img {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.add-page-content-img-button {
    width: 100%;
    display: flex;
    align-items: center;
}

.lesson-img-display {
    margin-top: 10px;
    width: 100%;
    max-width: 350px;
    /* height: 200px; */
    /* border: 1px solid #000; */
}

.lesson-img-display img{
    max-width: 350px;
}

.question-actions {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Align items to the left */
}

.question-action-icon {
    margin-right: 10px; /* Add spacing between icons */
    cursor: pointer;
}

.additional-content {
    margin: 0 18px;
    /* width: 26rem; */
    width: 392px;
}

.content-autosave {
    /* position: relative; */
    height: 25px;
    margin-top: -25px;
    color: rgba(255, 0, 0, 0.75);
}

.general-module {
    /* font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 8px;
    margin-top: 20px; */
    display: flex;
    flex-direction: column;
}


.general-module-title {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 8px;
    margin-top: 20px;
    /* display: flex;
    flex-direction: column; */
}

