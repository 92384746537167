
.options-display {
    margin-left: 112px;
    margin-top: 0px;
}

.render-options-list {
    width: 100%;
    margin-bottom: 20px;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.option-list-content {
    margin-left: 0px;
}

.renderOptionBodyImg {
    display: flex;
    flex-direction: column;
    align-items: center; 
}

.mc_img {  
    margin-top: 10px;
    max-width: 300px;
    max-height: 250px;
}