.addImage {
    font-size: 28px;
    margin-top: 22px;
    margin-left: 8px;
    cursor: pointer;
}

.modal-content {
    font-size: 26px;
}

input {
    font-size: 16px;
}
