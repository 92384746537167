* {
    box-sizing: border-box;
}

/* body {
    display: flex;
    justify-content: center;
    width: 100%;
    min-width: 1400px;
    background-color: rgb(255, 255, 255);
} */

.body {
    display: flex;
    justify-content: center;
    min-width: 1400px;
    width: 100%;
    background-color: rgb(255, 255, 255);
    /* border-radius: 2px; */
    /* padding: 5px; */
}

.lesson-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    background-color: white;
    border-radius: 0.5rem;
    /* margin-top: 1rem; */
    /* min-width: 1400px; */
}

.lesson-body {
    display: flex;
    width: 100%;
    /* min-width: 1300px; */
    justify-content: center;
    padding: 3rem;
    background-color: white;
    /* border-radius: 0.5rem; */
    /* margin-top: 1rem; */
}

.personal-lesson-list {
    width: 24%;
    height: 150%;
    /* border: 1px solid #ccc; 
    padding: 15px;
    border-radius: 10px;
    background-color: #f0f0f0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); */
}

.lesson-list {
    /* width: 28%; */
    width: 100%;
    border: 1px solid #ccc; 
    padding: 15px;
    height: 100%;
    border-radius: 10px;
    background-color:  #f0f0f0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.lesson-item-bank {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    height: 60.42px;
    width: 100%;
}

.lesson-item-bank:hover,
.lesson-item-bank:focus {
    background-color: #e8e8e8;
}

.lesson-title {
    cursor: pointer;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 1rem;
}

.lesson-title.active {
    background-color: #d2eac4;
    color: #000000;
}

.lesson-bank-img-author {
    display: flex;
    align-items: center;
    flex-grow: 1;
    width: 100px;
}

.lesson-bank-author {
    display: flex;
    flex-direction: column;
    /* margin-right: 10px; */
}

.lessoncard-content {
    margin-left: 10px;
}

.lessoncard {
    display: flex;
}

.lessoncard-img-author {
    display: flex;
    flex-direction: column;
}

.lessoncard-descrip {
    /* width: 200px; */
    width: 380px;
    word-wrap: break-word; 
    word-break: break-word;
    hyphens: auto;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
}

.rating-container {
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 15px;
}

.lessoncard-comment {
    /* width: 200px; */
    width: 380px;
    word-wrap: break-word; 
    word-break: break-word;
    hyphens: auto;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
}

.lesson-bank-title {
    width: 26.5%;
}