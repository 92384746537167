
.hand-module-body {
    display: flex;
    /* flex-direction: column; */
    /* align-items: center; */
    margin-top: 20px;
}

/* .input-row {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 5px 0px; 
    margin-top: 10px;
}

.hand-input {
    margin: 0 5px;
    flex-grow: 1;
    width: 100px;
} */

/* .hand-module-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
} */

/* .hand-module-image img{
    max-width: 885px;
} */

.image-lesson-handmodule {
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    text-align: center; 
}

.image-lesson-handmodule img{
    max-width: 350px;
    max-height: 550px;
    margin-bottom: 40px;
    justify-content: center;
} 

.hand-module-input {
    width: 650px;
    display: flex;
    flex-direction: column;
}

.radio-row {
    display: flex;
    justify-content: flex-start;
    user-select: none;
    width: 200px;
}

.hand-module-input .ant-radio-group {
    flex-direction: column;
}


.hand-input {
    margin: 0 5px;
    flex-grow: 1;
    width: 100%;
    /* width: 300px; */
} 

.input-button {
    display: flex;
    width: 580px;
    align-items: center;
    justify-content: center;
}

.display-list-gestsure {
    margin: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid #000000;
    width: 250px;
    user-select: none;
}

.display-gesture {
    display: flex;
    align-items: center;
}

/* Add this to your CSS file */
.hide-spinner .ant-input-number-handler-wrap {
    display: none;
}

.hide-spinner .ant-input-number-input {
    /* padding-right: 7px; */
}
