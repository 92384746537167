* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
.body {
    display: flex;
    justify-content: center;
    min-width: 1400px;
    width: 100%;
    background-color: rgb(255, 255, 255);
}

.home-container {
    min-width: 1400px;
    width: 100%;
    display: flex;
    height: auto;
    flex-direction: column; 
    justify-content: center;
}

.tangibook-homepage {
    /* width: 98%; */
    width: 1350px;
    display: flex;
    justify-content: center;
    flex-grow: 1; 
    height: auto;
    margin: 0 auto;
    margin-top: 20px;
}
.tangibook-img, .tangibook-description {
    flex-grow: 1; /* Allows both items to expand and fill the available space */
}


.prereview-side {
    /* position: relative; */
    min-width: 350px;
    max-width: 350px;
    margin-top: 10px;
    margin-right: 20px;
}
.tangibook-homepage {
    display: flex;
    justify-content: center;
    height: auto;
}

.tangibook-procedure-img {
    border-radius: 3px;
}

.tangibook-img {
    display: flex;
    flex-direction: column;
    width: 960px;
    /* width: 100%; */
    /* height: 480px; */
    /* height: 75%; */
    /* border-radius: 3px; */
    border-radius: 10px; 
    overflow: hidden; 
}

.procedures-img {
    /* height: 200px; */
    /* height: 28%; */
    /* width: 100%; */
    width: 960px;
}

.tangibook-description {
    width: 400px;
    /* width: 40%; */
    /* margin-top: 20px; */
    margin-left: 20px;
    height: 95%;
    /* height: 545px; */
    /* height: 77%; */
    padding: 10px;
    background-color: rgb(198, 202, 198);
    border-radius: 10px;
    display: flex;
    flex-direction: column; 
    justify-content: center;
}

.description {
    padding: 20px;
    /* font-size: 28px; */
    font-size: 1.85rem;
    text-align: justify;
    hyphens: auto;
    margin-top: 70px;
}

/* .button-start {
    width: 100%;
    margin-top: 35%

} */

.button-start {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;   
    height: 100%; 
}
