/* body {
    display: flex;
    justify-content: center;
    min-width: 1400px;
} */

.prereview-container {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    min-width: 1400px;
    background-color: #fff;
    padding-bottom: 40px;
}

.prereview-side {
    /* position: relative; */
    min-width: 350px;
    max-width: 350px;
    margin-top: 10px;
    margin-right: 40px;
}

.question-title-prereview {
    width: 90%;
    white-space: nowrap;    
    overflow: hidden;      
    text-overflow: ellipsis; 
}

.prereview-body {
    display: flex;
    justify-content: center;
    align-items: center; 
    flex-direction: column; 
}

.prereview-layout {
    width: 780px;
    height: 602px;
    display: flex;
    flex-direction: column;
    justify-content: center;  
    align-items: center;    
    margin-top: 20px;
    border: 1px solid black;  
}

.prereview-pdf {
    width: 100%;
    height: 100%;
}

.page_identifier {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.page_identifier img {
    margin-top: 12px;
    margin-bottom: 4px;
    margin-right: 272px;
    width: 238px;
}

.prereview-body-container {
    /* display: flex; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(2, 1fr);
    /* flex-direction: column; */
    /* align-items: center; */
    margin-top: 3px;
    /* height: 85%; */
    height: 510px;
}

.block-large {
    grid-column: 1 / 2; /* 1st column */
    grid-row: 1 / 3; /* 2 rows */
    height: 100%;
}

.block-small {
    grid-column: 2 / 3; /* 2nd column */
    height: 250px;
}

.block-large, .block-small {
    /* border: 1px solid #ccc; */
}

.page {
    border: 1px solid #ccc;
    padding: 0px;
    margin-bottom: 20px;
    width: 50%; 
    /* max-width: 390px; */
    display: flex;
    justify-content: space-around;
}

.question-slot {
    flex-basis: 100%; 
    /* border: 1px dashed #aaa; */
    padding: 0px;
    /* min-height: 100px;  */
    height: 480px;
    display: flex;
    flex-direction: column; 
    /* align-items: center; */
    /* justify-content: center; */
    /* text-align: center; */
}

.question-item-prereivew {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
}

.unselectable {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Chrome, Safari */
}

.question-slot-title {
    height: 60px;
    margin-left: 30px;
    font-size: 15px;
    margin-bottom: 5px;
}

.question-slot-options {
    margin-left: 0px;
    margin-top: 0px;
}

.matching-style {
    height: 35.5px;
    /* height: 35.5px; */
    /* font-size: 14.5px;
    margin-bottom: 1.5px; */
}
