
.lens-module-body {
    display: flex; 
    /* justify-content: space-between;
    flex-wrap: wrap;  */
    margin: 20px;
}

.input-group {
    width: 48%; 
    display: flex;
    flex-direction: column; 
    margin-bottom: 10px; 
}

.input-label {
    margin-bottom: 5px; 
}


.image-lesson-lensmodule {
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    text-align: center; 
}

.image-lesson-lensmodule img{
    max-width: 350px;
    /* max-height: 550px; */
    margin-bottom: 40px;
    justify-content: center;
} 


.lens-module-input {
    width: 650px;
    display: flex;
    flex-direction: column;
}

.radio-row-lens {
    display: flex;
    justify-content: flex-start;
    width: 220px;
    user-select: none;
}

.lens-module-input .ant-radio-group {
    flex-direction: column;
}

.lens-input {
    margin: 0 5px;
    flex-grow: 1;
    width: 100%;
    /* width: 300px; */
} 

.input-button-lens {
    display: flex;
    width: 500px;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    margin-top: 10px;
}

.lens-msg-display {
    width: 380px;
    user-select: none;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
}