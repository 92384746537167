/* .createlesson-container {
    display: flex;
    flex-direction: column;
    min-width: 1300px;
    justify-content: center;
    padding: 3rem;
    background-color: white;
    border-radius: 0.5rem;
    margin-top: 1rem;
} */

.container-createlesson {
    display: flex;
    flex-direction: row;
}

.createlesson-steps {
    margin-bottom: 25px;
}

.createlesson-page-body {
    display: flex;
    flex-direction: column;
    min-width: 1000px;
    justify-content: center;
    /* padding: 3rem; */
}

.createlesson-page-title {
    display: flex;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 25px;
}

.createlesson-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.newlesson-content-left {
    display: flex;
    flex-direction: column;
    width: 25rem;
    margin-right: 5rem;
}

.createlesson-content-title {
    margin-bottom: 20px;
}

.createlesson-content-description {

}

.newlesson-content-right {
    display: flex;
    flex-direction: column;
    width: 25rem;
    /* margin-right: 10px; */
}

.createlesson-content-right-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.lesson-bank-list-import {
    display: flex;
    width: 130%;
}

.lesson-bank-list {
    width: 100%;
}

.handleCreateLesson {
    display: flex;
    justify-content: flex-end;
    margin-right: 10.5rem;
}

.lesson-bank-import {
    display: flex;
    align-items: flex-end;
    /* margin-left: 10px; */
    position: relative;
    right: -40px;
}


/* .lesson-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    height: 40px;
    
}

.lesson-item:hover,
.lesson-item:focus {
    background-color: #e8e8e8;
}

.lesson-title {
    cursor: pointer;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 1rem;
}

.lesson-title.active {
    background-color: #d2eac4;
    color: #000000;
} */